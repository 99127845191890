import React from "react";
import { createGlobalStyle } from "styled-components";
import { Navbar } from "../components/styled/Navbar";
import { Footer } from "./styled/Footer";

const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  body {
    font-family: Helvetica, sans-serif;
  }

 :root{
    --primary-color: #d64445;
 }
`;
export default function Layout({ children, location }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navbar location={location} />
      {children}
      <Footer />
    </React.Fragment>
  );
}
