import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

export const Navbar = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Nav location={location.pathname}>
      <Logo to="/">OS</Logo>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        <span />
        <span />
        <span />
      </Hamburger>
      <Menu isOpen={isOpen}>
        <MenuLink to="/">Home</MenuLink>
        <MenuLink to="/blog">Blog</MenuLink>
        <MenuLink to="/whoweare">Who We Are</MenuLink>
      </Menu>
    </Nav>
  );
};

const MenuLink = styled(Link)`
  margin: 1rem 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease-in;
  font-size: 1rem;
  font-weight: 700;
  &:hover {
    color: #444;
  }
`;

const Nav = styled.nav`
  padding: 0 2rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${(props) =>
    props.location === "/" ? "rgba(0, 0, 0, 0)" : "var(--primary-color)"};
  position: ${(props) => (props.location === "/" ? "absolute" : "static")};
  z-index: 100;
  width: 100%;
`;

const Logo = styled(Link)`
  margin: 1rem 0;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.7rem;
  span {
    font-weight: 300;
    font-size: 1.3rem;
  }
  transition: all 0.3s ease-in;
  &:hover {
    color: #444;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: max-height 0.3s ease-in;
    width: 100%;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 2px;
    width: 25px;
    background: #fff;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;
