import React from "react";
import styled from "styled-components";
import { FaFacebook, FaInstagram } from "react-icons/fa";

export const Footer = () => {
  return (
    <Container>
      <div className="flex-container">
        <p className="tag">
          Open Session is a student-support group that is committed to provide
          an avenue for them to express themselves and live life to what matters
          most.
        </p>
        <div>
          <h3 style={{ margin: ".5rem 0" }}>Stay Updated</h3>
          <a href="https://www.facebook.com/opensession.lcsrc">
            <FaFacebook
              size={30}
              style={{ fill: "white", marginRight: ".5rem " }}
            />
          </a>

          <a href="https://www.instagram.com/opensession/">
            <FaInstagram size={30} style={{ fill: "white" }} />
          </a>
        </div>
      </div>
      <hr />
      <p className="copy">Open Session © 2021</p>
    </Container>
  );
};

const Container = styled.footer`
  background-color: var(--primary-color);
  color: white;
  .copy {
    text-align: center;
  }
  hr {
    color: white;
    margin: 1rem 1rem;
  }
  padding: 1rem;
  .flex-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
  }
  ul {
    list-style: none;
  }
  .tag {
    max-width: 300px;
  }
`;
